<template>
  <div class="haishiDetails">
    <div class="content">
      <div class="left">
        <!-- 基本概况 -->
        <div class="left_info">
          <img class="bck" src="./image/bck.png" alt="" />
          <div class="left_info_box">
            <img :src="haishi.hstp" alt="" />
            <div class="info">
              <div class="info1">
                <span>{{ haishi.name|- }}</span>
                <span><i class="iconfont xhgpin"></i> {{ haishi.gj|- }}</span>
                <span>{{ haishi.hd_hdsf_one|- }}</span>
              </div>
              <div class="info2">
                <p>{{ haishi.school.zwmc|- }}</p>
                <div>
                  {{ haishi.school.education|- }} | {{ haishi.major|- }}
                </div>
              </div>
              <div class="info3">
                <p>评分：</p>
                <span>{{ haishi.hd_ztdf|- }}</span>
              </div>
            </div>
            <div class="btn">
              <span @click="yuyue($route.query.id)">预约</span>
              <span @click="share($route.query.id)">分享</span>
            </div>
          </div>
          <div class="title">
            基本概况
          </div>
          <div class="left_info_yycj">
            <div v-for="(item, key) in haishi.hd_yycj" :key="key" :title="item">
              <span>{{ item }}</span>
            </div>
          </div>
          <div class="left_info_shanchang">
            <div v-if="haishi.cgkc.length > 0">
              <span>常规：</span>
              <span v-for="item in haishi.cgkc" :key="item" :title="item">{{ item }}</span>
            </div>
            <div v-if="haishi.ap.length > 0">
              <span>AP：</span>
              <span v-for="item in haishi.ap" :key="item" :title="item">{{ item }}</span>
            </div>
          </div>
          <div class="zbcharts" ref="charts"></div>
        </div>
        <!-- 服务评价 -->
        <div class="left_pingjia">
          <div class="title">
            服务评价
          </div>
          <template v-if="pingjia.length>0">
            <div
              class="pingjia_items"
              v-for="(item, key) in pingjia"
              :key="key"
            >
              <div class="pingjia_items_top">
                <div class="info">
                  <img :src="item.avatar" alt="" />
                  <span>{{ item.bb_user_name }}</span>
                  <el-rate
                    v-model="item.evaluation_list_show.star"
                    disabled
                    :colors="['#F5C98E', '#F5C98E', '#F5C98E']"
                    text-color="#ff9900"
                  >
                  </el-rate>
                </div>
                <!-- <div class="bofang">
                  <i class="iconfont xhgplay"></i>
                  播放
                </div> -->
              </div>
              <div class="pingjia_items_cnt">
                {{ item.evaluation_list_show.content }}
              </div>
            </div>
          </template>
          <div v-else class="pj_null">
             <img  src="@/assets/pj_null.png" alt="">
             <span>暂未收到评价</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">
          推荐海师
          <span @click="getTuijian"
            ><i class="xhgrefresh iconfont"></i>换一批</span
          >
        </div>
        <div class="tuijian_items" v-for="(item, key) in tuijian" :key="key" @click="goDetaile(item.id)">
          <img :src="item.hstp" alt="" />
          <div class="tuijian_items_box">
            <div class="t1">
              <p :title="item.name">{{ item.name }} </p>
            </div>
            <div class="t2">
               <span :title="item.hd_hdsf_one">{{ item.hd_hdsf_one }}</span>
              <div>
                <span
                  :title="item.school.education"
                  >{{ item.school.education|- }}</span
                >
                · <span :title="item.major">{{ item.major|- }}</span>
              </div>
            </div>
            <div class="t3"><p :title="item.school.zwmc">{{ item.school.zwmc }}</p></div>
          </div>
        </div>
      </div>
    </div>
    <share v-if="flag" @share="share" :share_url="share_url" />
  </div>
</template>

<script>
// import breadcrumb from "@/components/el-breadcrumb/el-breadcrumb.vue";
import { getRecommend, getDetails, getPjList } from "@/api/haishi";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import share from '@/views/haishi/components/share'

export default {
  components:{share},
  // components: { breadcrumb },
  data() {
    return {
      haishi: {
        ap: [],
        cgkc: [],
        contact: {},
        gj: "",
        list:[],
        hd_gj: "",
        hd_gwbkyx: null,
        hd_gwbkzy: null,
        hd_gwbsxx: null,
        hd_gwbszy: null,
        hd_gwssxx: "",
        hd_gwsszy: "",
        hd_hdsf: "",
        hd_wsjl:
          "",
        hd_yycj: [
        ],
        hd_ztdf: "",
        hstp:
          "",
        id: "",
        look: {},
        major: "",
        name: "",
        school: {},
        student: {},
      },
      pingjia: [
        {
          avatar: "",
          bb_user_name: "",
          evaluation_list_show: {},
          id: 39,
        },
      ],
      chart:null,
      share_url:"",//分享url
      flag:false,//分享弹窗
      tuijian: [], //推荐海师
    };
  },
  created(){
    //判断移动还是pc
    this.browserRedirect()
  },
  async mounted() {
    
    this.getTuijian();
    this.getPingjia();
    this.$nextTick(()=>{
      this.getDtl();
    })
  },
  methods: {
    browserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
      ) {
        // window.location.href = "移动端网站地址";
        this.$router.push(`/MhsDetail?id=${this.$route.query.id}`)
      } else {
        // window.location = "PC端网站地址";
        // this.$router.push(`/haishi/haishiDetails?id=${this.$route.query.id}`);
      }
    },
    //获取评价列表
    getPingjia() {
      getPjList({
        hd_id: this.$route.query.id,
      }).then((res) => {
        this.pingjia = res.data;
        console.log(res);
      });
    },
    //推荐海师
    getTuijian() {
      getRecommend({
        page: this.page,
        hd_id: this.$route.query.id,
      }).then((res) => {
        this.tuijian = res.data;
      });
    },
    //获取海师详情信息
    async getDtl() {
      await getDetails({
        hd_id: this.$route.query.id,
      }).then((res) => {
        this.haishi = res.data;
        this.charts(res.data.list);
      });
    },
    yuyue(id){
      if(this.$cookie.get('infor')){
        this.$router.push(`/haishi/haishiSub?id=${id}`)
      }else{
        this.$store.state.login=true
      }

    },
    //分享
    share(id){
      if(id){
        this.share_url = `${this.$store.state.currentUrl}/#/MhsDetail?id=${id}`
      }
      this.flag = !this.flag
    },
    //图表
    charts(data) {
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create(this.$refs.charts, am4charts.PieChart);
      this.chart = chart
      // Add data
      chart.data = data;
      chart.padding(0, 20, 0, 20);
      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "name";
      pieSeries.slices.template.stroke = am4core.color("#fff");
      // pieSeries.slices.template.strokeOpacity = 1;
      // pieSeries.labels.template.text = "[#666666]{name}{value}次[/]";
      pieSeries.labels.template.text = `[#333333 text-align:center] {value}次[/] \n [#999999 text-align:center]{name}[/]`;
      pieSeries.slices.template.tooltipText = "[#ffffff text-align:center]{category}: {value.value}次[/]";
      pieSeries.legendSettings.valueText = "{valueY.close}";
      //添加白线分割
      pieSeries.slices.template.stroke = am4core.color("#FFFFFF");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      //旋转进场动画
      pieSeries.hiddenState.properties.endAngle = -90;
      // chart.hiddenState.properties.radius = am4core.percent(0);
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.paddingRight = 100
      chart.legend.labels.template.fill = am4core.color("#62472C");
      chart.legend.valueLabels.template.fill = am4core.color("#62472C");
      var markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 20;
      markerTemplate.height = 20;
      pieSeries.colors.list = [
        am4core.color("#F5C98D"),
        am4core.color("#F4C27D"),
        am4core.color("#F3BC70"),
      ];
    },
    goDetaile(id){
      this.$router.push(`/haishi/haishiDetails?id=${id}`)
      location.reload()
    }
  },
  beforeDestroy(){
    // console.log('销毁')
    // this.chart.dispose()
  }
};
</script>

<style scoped lang="scss">
.haishiDetails {
  background: #f5f5f5;
  padding-bottom: 40px;
  .content {
    width: 1200px;
    margin: auto;
    display: flex;
    //公共标题样式
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      padding-top: 10px;
      position: relative;
      display: flex;
      justify-content: space-between;
      z-index: 2;
      &::before {
        content: "";
        width: 30px;
        height: 30px;
        background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
        position: absolute;
        border-radius: 50%;
        bottom: 2px;
        left: 56px;
        z-index: -1;
      }
    }
    //左大块
    .left {
      width: 890px;
      margin-right: 10px;
      .left_info {
        width: 890px;
        background: #ffffff;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        padding: 0 20px;
        box-sizing: border-box;
        .bck {
          width: 100%;
          height: 112px;
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0px;
          left: 0px;
        }
        .left_info_box {
          height: 110px;
          margin-top: 100px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          margin-bottom: 38px;
          img {
            width: 110px;
            height: 110px;
            border-radius: 50%;
            margin-right: 20px;
            border: 4px solid white;
            box-sizing: border-box;
            background: white;
            object-fit: cover;
          }
          .info {
            flex: 1;
            padding-top: 20px;
            > div {
              display: flex;
              align-items: center;
            }
            .info1 {
              span:nth-of-type(1) {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                margin-right: 10px;
              }
              span:nth-of-type(2) {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                margin-right: 10px;
              }
              span:nth-of-type(3) {
                display: inline-block;
                background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
                border-radius: 8px 2px 8px 2px;
                font-size: 14px;
                font-weight: 500;
                color: #935d14;
                padding: 2px 6px;
              }
            }
            .info2 {
              margin-top: 10px;
              p {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                margin-right: 15px;
              }
              div {
                font-size: 16px;
                font-weight: 400;
                color: #666666;
              }
            }
            .info3 {
              margin-top: 10px;
              p {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
              }
              span {
                font-size: 16px;
                font-weight: bold;
                color: #f56c6c;
              }
            }
          }
          .btn {
            padding-top: 20px;
            span {
              width: 90px;
              height: 38px;
              border-radius: 4px;
              border: 1px solid #cccccc;
              display: block;
              box-sizing: border-box;
              text-align: center;
              line-height: 38px;
              cursor: pointer;
              &:hover {
                background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
                border-radius: 4px;
                color: #935d14;
                border: transparent;
              }
            }
            span:nth-of-type(1) {
              margin-bottom: 10px;
            }
          }
        }
        .left_info_yycj {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          margin-top: 24px;
          div {
            position: relative;
            margin-right: 30px;
            margin-bottom: 15px;
          }
          span {
            display: inline-block;
            background: #f8ece1;
            font-size: 14px;
            font-weight: 500;
            color: #d99d66;
            padding: 4px 0px;
            padding-left: 8px;
            padding-right: 10px;
            border-radius: 4px;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .left_info_shanchang {
          display: flex;
          justify-content: center;
          margin-top: 25px;
          > div {
            margin: 0px 30px;
            span {
              max-width: 100px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              display: inline-block;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              margin-right: 20px;
              padding-bottom: 6px;
            }
            span:nth-of-type(1) {
              font-weight: 550;
            }
          }
        }
        .zbcharts {
          width: 100%;
          height: 270px;
          position: relative;
          margin-top: 40px;
          bottom: 20px;
          left: 0px;
          &::after {
            content: "";
            width: 100px;
            height: 50px;
            background: white;
            position: absolute;
            bottom: 0px;
            left: 0px;
          }
        }
      }
      .left_pingjia {
        margin-top: 10px;
        width: 890px;
        background: #ffffff;
        border-radius: 8px;
        padding: 20px 0px;
        .title {
          padding: 0px 20px;
          margin-bottom: 10px;
          &::before {
            left: 76px;
          }
        }
        .pingjia_items {
          width: 100%;
          padding: 15px 20px;
          &:hover {
            background: #fafafa;
          }
          .pingjia_items_top {
            display: flex;
            justify-content: space-between;
            .info {
              display: flex;
              align-items: center;
              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 12px;
              }
              span {
                font-size: 18px;
                font-weight: 400;
                color: #333333;
                display: block;
                width: 100px;
              }
              /deep/ .el-rate {
                .el-rate__icon {
                  font-size: 22px;
                  // color: #F8E2C4 !important;
                }
              }
            }
            .bofang {
              height: 32px;
              border-radius: 6px;
              border: 1px solid #ededed;
              text-align: center;
              line-height: 32px;
              color: #999999;
              padding: 0 6px;
              font-size: 14px;
              cursor: pointer;
              transition: all 0.5s;
              i {
                color: #ededed;
                transition: all 0.5s;
              }
              &:hover {
                color: #1890ff;
                border: 1px solid rgba(24, 144, 255, 0.3);
                i {
                  color: #1890ff;
                }
              }
            }
          }
          .pingjia_items_cnt {
            margin-top: 15px;
          }
        }
        .pj_null{
          height: 500px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          span{
            font-size: 16px;
            font-weight: 400;
            color: #B1C2D9;
          }
        }
      }
    }
    //右大块
    .right {
      width: 300px;
      height: 916px;
      background: #ffffff;
      border-radius: 8px;
      padding: 0px 20px;
      padding-top: 10px;
      box-sizing: border-box;
      .title {
        padding-top: 20px;
        span {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
          i {
            margin-right: 10px;
            color: #999999;
          }
          &:hover {
            i {
              color: #1890ff;
            }
          }
        }
      }
      .tuijian_items {
        height: 108px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        > img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
        > .tuijian_items_box {
          width: 200px;
          height: 100%;
          border-bottom: 1px solid #ededed;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .t1 {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            display: flex;
            align-items: center;
            p{
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
            }
          }
          .t2 {
            display: flex;
            align-items: center;
            margin-top: 7px;
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            >span{
              font-weight: 400;
              color: #D99D66;
              max-width: 90px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            > p {
              max-width: 90px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            > div {
              max-width: 136px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-left: 10px;
            }
          }
          .t3{
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            margin-top: 7px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &:last-child {
          > div {
            border: none;
          }
        }
      }
    }
  }
}
</style>
